export const regionDomains = {
  ru: 'www.giftery.ru',
  kz: 'www.gifterycard.kz',
  by: 'www.giftery.by',
}

export const regionCountries = {
  ru: 'Россия',
  kz: 'Казахстан',
  by: 'Беларусь',
}

export const regionAddresses = {
  ru: 'Москва, Технопарк «Строгино», ул.&nbsp;Твардовского, д.&nbsp;8, стр.&nbsp;1, офис&nbsp;313',
  kz: '050000, Республика&nbsp;Казахстан, г.&nbsp;Алматы, р.&nbsp;Алмалинский, пр-т&nbsp;Назарбаева, д.&nbsp;99/57, Литер&nbsp;А, 1&nbsp;этаж, офис&nbsp;12',
  by: 'г.&nbsp;Минск, ул.&nbsp;Максима&nbsp;Богдановича, д.&nbsp;138, пом.&nbsp;298'
}

export const supportPhones = {
  ru: '8 800 700 90 90',
  kz: '+7 727 310 82 34',
  by: '+375 (44) 750-50-37'
}

export const supportEmails = {
  ru: 'support@giftery.ru',
  kz: 'support@gifterycard.kz',
  by: 'support@giftery.by'
}

export const workingHours = {
  ru: 'ежедневно с 08:00 до 20:00 (МСК)',
  kz: 'ежедневно с 10:00 до 23:00',
  by: 'с понедельника по пятницу c 9:00 до 18:00'
}

export const regionYandexMapUrls = {
  ru: 'https://yandex.ru/map-widget/v1/?um=constructor%3Ab019303ded192de75c2b79c50106568d3ff2fb91a7ba86f11cdba89677099efe&amp;source=constructor',
  kz: 'https://yandex.ru/map-widget/v1/?um=constructor%3Af4e6d27e9f3e94e0e22083335d0126b36285644f8f562ee33cd77e37fea1f1df&amp;source=constructor',
  by: 'https://yandex.ru/map-widget/v1/?um=constructor%3A63e920d95e4e9de8dfe92c6658f9f3a04aa43b172ba78b0a26bb999b9cc85fd8&amp;source=constructor'
}

export const currency = {
  ru: '₽',
  kz: '₸',
  by: 'р.'
}

export const currencyCode = {
  ru: 'RUB',
  kz: 'KZT',
  by: 'BYN'
}

export const examplePhone = {
  ru: '+7 (912) 345-67-89',
  kz: '+7 (712) 345-67-89',
  by: '+375 (12) 345-67-89'
}

export const phoneMask = {
  ru: ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  kz: ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  by: ['+', '3', '7', '5', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
}

export const regionClientId = {
  ru: 213,
  kz: 14055,
  by: 17061,
  es: 16182
}

export const gifterySupplierId = [
  342,
  13932,
  17062
]

export const timeZone = {
  kz: {
    zone: 'UTC+5',
    zoneId: 'Asia/Almaty'
  },
  ru: {
    zone: 'UTC+3',
    zoneId: 'Europe/Moscow'
  },
  es: {
    zone: 'UTC+1',
    zoneSummer: 'UTC+2',
    zoneId: 'CET'
  },
  by: {
    zone: 'UTC+3',
    zoneId: 'Europe/Minsk'
  }
}

export const domains = [
  'yahoo.com',
  'hotmail.com',
  'gmail.com',
  'me.com',
  'aol.com',
  'mac.com',
  'live.com',
  'comcast.net',
  'googlemail.com',
  'msn.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'facebook.com',
  'verizon.net',
  'sbcglobal.net',
  'att.net',
  'gmx.com',
  'outlook.com',
  'icloud.com',
  'mail.ru',
  'yandex.ru',
  'ya.ru',
  'yandex.com',
  'rambler.ru',
  'bk.ru',
  'inbox.ru',
  'list.ru',
  'protonmail.com',
  'pm.me',
  'narod.ru'
]

export const defaultDomains = {
  kz: [
    'mail.ru',
    'gmail.com',
    'icloud.com',
    'yandex.ru',
    'outlook.com',
    'rambler.ru'
  ],
  ru: [
    'mail.ru',
    'gmail.com',
    'icloud.com',
    'yandex.ru',
    'outlook.com',
    'rambler.ru'
  ],
  by: [
    'mail.ru',
    'gmail.com',
    'icloud.com',
    'yandex.ru',
    'outlook.com',
    'rambler.ru'
  ],
  es: [
    'gmail.com',
    'icloud.com',
    'outlook.com',
    'hotmail.com',
    'yahoo.com',
    'live.com'
  ]
}

export const EnumTaskStatus = Object.freeze({
  pending: 'pending',
  reviewing: 'reviewing',
  checking: 'checking',
  done: 'done',
  canceled: 'canceled',
})

export const EnumTaskType = Object.freeze({
  approvement_request: 'approvement_request',
  act_request: 'act_request',
  user_data_request: 'user_data_request',
})

export const taskTypesLabel = {
  [EnumTaskType.approvement_request]: 'Запрос подтверждающих документов',
  [EnumTaskType.act_request]: 'Запрос акта',
  [EnumTaskType.user_data_request]: 'Запрос данных пользователя',
}

export const taskStatusesLabel = {
  [EnumTaskStatus.pending]: 'Новое',
  [EnumTaskStatus.reviewing]: 'Проверяется',
  [EnumTaskStatus.checking]: 'Получено',
  [EnumTaskStatus.done]: 'Готово',
  [EnumTaskStatus.canceled]: 'Отклонено',
}